<template>
  <GradientBackLayout
    class="WelcomePageV2"
    :class="{ hideGamification: communityInfo.hideGamification }"
    :title="$filters.translate('welcome').toUpperCase()"
    :shadowText="$filters.translate('welcome').toUpperCase()"
  >
    <BoxShadow class="WelcomePageV2--Box">
      <div
        class="WelcomePageV2--Sidebar"
        v-if="!communityInfo.hideGamification"
      >
        <div class="WelcomePageV2--GrayBox">
          <h1>{{ $t("welcome_get_labs") }}</h1>
          <div
            class="WelcomePageV2--PointsBox"
            v-if="communityInfoEditable.pointsCommunityActive > 0"
          >
            <p>+{{ communityInfoEditable.pointsCommunityActive }}</p>
            <p>{{ $t("community_welcome_be_active") }}</p>
          </div>
          <div
            class="WelcomePageV2--PointsBox"
            v-if="communityInfoEditable.pointsActivityParticipated > 0"
          >
            <p>+{{ communityInfoEditable.pointsActivityParticipated }}</p>
            <p>{{ $t("community_welcome_activity_participate") }}</p>
          </div>
          <div
            class="WelcomePageV2--PointsBox"
            v-if="communityInfoEditable.pointsCommentAppreciated > 0"
          >
            <p>+{{ communityInfoEditable.pointsCommentAppreciated }}</p>
            <p>{{ $t("welcome_labs_get_gold_medal") }}</p>
          </div>
        </div>
        <div class="WelcomePageV2--GrayBox" v-if="communityInfoEditable.pointsExplanation">
          <h2>{{ $t("welcome_labs_text_plain") }}</h2>
          <p class="WelcomePageV2--LabsText" v-html="communityInfoEditable.pointsExplanation">
          </p>
        </div>
      </div>

      <div class="WelcomePageV2--Content">
        <div class="WelcomePageV2--Title">
          <h1 v-if="!theme.loginWelcomeTextBlack">
            {{ $t("login_welcome_text") }}
          </h1>
          <h1 v-if="theme.loginWelcomeTextBlack">
            {{ theme.loginWelcomeTextBlack }}
          </h1>
          <h2 v-if="!theme.loginWelcomeTextColor && communityInfo.watlab" :style="{ color: theme.mainColor }">WAT Lab</h2>
          <h2 v-if="!theme.loginWelcomeTextColor && !communityInfo.watlab" :style="{ color: theme.mainColor }">Experience Lab</h2>
          <h2 v-if="theme.loginWelcomeTextColor" :style="{ color: theme.mainColor }">{{theme.loginWelcomeTextColor}}</h2>
        </div>
        <div
          class="WelcomePageV2--ModeratorBox"
          v-show="
            isClient ||
            (isUser &&
              communityInfoEditable.moderatorInfo != undefined &&
              communityInfoEditable.moderatorInfo != null &&
              communityInfoEditable.moderatorInfo.length > 0)
          "
        >
          <div
            class="WelcomePageV2--Logo"
            :style="{
              'background-image':
                'url(\'' + communityInfoEditable.moderatorImage + '\')',
            }"
          >
          </div>
          <div class="WelcomePageV2--ModeratorInfo">
            <h1>{{ $t("role_moderator") }}</h1>
            <p>{{ communityInfoEditable.moderatorInfo }}</p>
          </div>
        </div>
        <div class="WelcomePageV2--instructions">
          <div
            class="WelcomePageV2--instructions--Show"
            v-html="communityInfoEditable.instructions"
          ></div>
        </div>
        <div class="WelcomePageV2--Media">
          <h1 v-if="images.length > 0">{{ $t("images") }}</h1>
          <MediaViewer
            :images="$filters.formatMediaWelcomePage(images)"
          />
        </div>
        <div class="WelcomePageV2--Media">
          <h1 v-if="videos.length > 0">{{ $t("videos") }}</h1>
          <MediaViewer
            :allowTitle="false"
            :videos="$filters.formatWelcomeVideo(videos)"
          />
        </div>
        <CommunityButton class="btn" v-on:click="$router.push({ name: 'activities' })">{{$t('button_go_activities')}}</CommunityButton>
      </div>
    </BoxShadow>
  </GradientBackLayout>
</template>

<script>
import { mapGetters } from "vuex";
import { map, flatMap } from "lodash";
import { VueEditor } from "vue3-editor";

import {
  COMMUNITY_UPDATE_INFO,
  COMMUNITY_FETCH_INFO,
} from "@/store/community/info.module";
import store from "@/store";

import MediaViewer from "~/components/MediaViewer";
import ApiService from "~/services/api.service";
import GradientBackLayout from "@/layouts/GradientBackLayout";
import BoxShadow from "@/components/BoxShadow.vue";
import CommunityButton from "@/components/CommunityButton.vue";

export default {
  name: "WelcomePage",

  components: {
    MediaViewer,
    GradientBackLayout,
    BoxShadow,
    CommunityButton
  },
  computed: {
    ...mapGetters([
      "communityInfo",
      "isClient",
      "isUser",
      "isModerator",
      "theme",
    ]),
  },
  data() {
    return {
      communityInfoEditable: {
        moderatorImage: "",
        moderatorInfo: "",
        videos: [],
        images: [],
        instructions: "",
        pointsExplanation: "",
        pointsCommunityActive: 0,
        pointsCommentAppreciated: 0,
        pointsActivityParticipated: 0,
      },
      images: [],
      videos: []
    };
  },
  methods: {
    fetchCommunityInfo: async function () {
      await store.dispatch(COMMUNITY_FETCH_INFO).then((data) => {
        this.communityInfoEditable.title = data.title;
        this.communityInfoEditable.moderatorImage = data.moderatorImage;
        this.communityInfoEditable.moderatorInfo = data.moderatorInfo;
        this.videos =
          data.videos != undefined
            ? data.videos.map(function (v) {
                return { path: v };
              })
            : [];
        this.images =
          data.images != undefined
            ? data.images.map(function (img) {
                return { path: img };
              })
            : [];
        this.communityInfoEditable.instructions = data.instructions;
        this.communityInfoEditable.pointsExplanation = data.pointsExplanation.replace(/\n/g, '<br>');
        this.communityInfoEditable.pointsCommunityActive =
          data.pointsCommunityActive;
        this.communityInfoEditable.pointsCommentAppreciated =
          data.pointsCommentAppreciated;
        this.communityInfoEditable.pointsActivityParticipated =
          data.pointsActivityParticipated;
      });
    }
  },
  async mounted() {
    await this.fetchCommunityInfo();
  },
};
</script>

<style lang="scss">
.WelcomePageV2--instructions {
  margin-top: 30px;
  &--Show {
    line-height: 1.42;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    ul,
    ol {
      padding-left: 1.5em;
      li {
        padding-left: 1.5em;
        list-style-type: none;
        &::before {
          content: "\2022";
          margin-left: -1.5em;
          margin-right: 0.3em;
          text-align: right;
          display: inline-block;
          white-space: nowrap;
          width: 1.2em;
        }
      }
    }
    blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }
    pre {
      background-color: #23241f;
      color: #f8f8f2;
      overflow: visible;
      white-space: pre-wrap;
      margin-bottom: 5px;
      margin-top: 5px;
      padding: 5px 10px;
      border-radius: 3px;
    }
  }
}
</style>
<style lang="scss" scoped>
.WelcomePageV2 {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  &.hideGamification {
    .WelcomePageV2--Box {
      grid-template-columns: auto;
    }
  }
  &--Edit {
    position: absolute;
    font-size: 25px;
    right: 10px;
    top: 10px;
    background: #f4f4f4;
    padding: 10px;
    border-radius: 50%;
  }
  &--Box {
    display: grid!important;
    grid-template-columns: 33% auto;
    grid-gap: 40px;
    padding: 50px!important;
    position: relative;
    > i{
        cursor: pointer;
    }
  }
  &--GrayBox {
    background: #f5f5f5;
    border: 1px solid #d3d3d3;
    padding: 23px;
    position: relative;
    margin-bottom: 14px;
    h1 {
      font-weight: bold;
      font-size: 25px;
      background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
      border: 2px solid #ffffff;
      box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.11);
      text-align: center;
      color: white;
      position: absolute;
      padding: 5px 20px;
      top: 16px;
      left: -16px;
    }
    h2 {
      font-size: 27px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    textarea {
      width: 100%;
      font-size: 18px;
      color: #747474;
      resize: none;
      height: 200px;
    }
  }
  &--LabsText {
    font-size: 18px;
    color: #747474;
  }
  &--PointsBox {
    display: flex;
    align-items: flex-start;
    background: white;
    border: 1px solid #e1e1e1;
    margin-top: 10px;
    padding: 10px;
    &:first-of-type {
      margin-top: 50px;
    }
    input {
      height: 50px;
      font-size: 40px;
      color: #96c800;
      font-weight: bold;
      max-width: 87px;
      width: auto;
      text-align: center;
      text-align: left;
    }
    p:first-child {
      font-size: 40px;
      letter-spacing: -0.07em;
      color: #96c800;
      font-weight: bold;
      margin-right: 10px;
      margin-top: -5px;
      display: flex;
      align-items: center;
    }
    p:last-child {
      font-size: 17px;
      color: #3e3e3e;
    }
  }
  &--Title {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -0.01em;
    color: #3e3e3e;
    h2 {
      color: var(--primary-color-text)!important;
    }
    @media only screen and (max-width: 600px) {
      h1{
        font-size: 45px;
      }
      h2{
        font-size: 40px;
      }
  }
  }
  &--ModeratorBox {
    padding: 15px;
    background: #f5f5f5;
    border: 1px solid #d3d3d3;
    display: flex;
    margin-top: 15px;
    h1 {
      font-size: 22px;
      letter-spacing: 0.01em;
      color: #3e3e3e;
    }
    p {
      font-size: 15px;
      color: #747474;
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
  }
  }
  &--ModeratorInfo {
    width: 100%;
    textarea {
      width: 100%;
      font-size: 15px;
      color: #747474;
      resize: none;
      height: 200px;
    }
  }
  &--Logo {
    width: 80px;
    min-width: 80px;
    height: 105px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    margin-right: 25px;
    &.edit {
      background-color: rgba(0, 0, 0, 0.5);
      position: relative;
      i {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        font-size: 20px;
        padding: 10px;
        background: #f4f4f4;
        border-radius: 50%;
      }
    }
  }
  &--Media {
    margin-top: 15px;
    h1 {
      text-transform: uppercase;
      font-size: 18px;
    }
    .MediaViewer {
      width: 20%;
    }
  }
  &--MediaList {
    display: flex;
    flex-wrap: wrap;
  }
  &--Uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    border: 1px solid #d3d3d3;
    color: #828282;
    width: 150px;
    height: 95px;
    margin: 0 10px 10px 0;
  }
  &--MediaItem {
    width: 150px;
    height: 95px;
    margin: 0 10px 10px 0;
    position: relative;
    background: #f5f5f5;
    border: 1px solid #d3d3d3;
    img {
      width: 100%;
      height: 100%;
    }
    video {
      width: 100%;
      height: 61px;
    }
    div {
      background: rgba(0, 0, 0, 0.5);
      height: 100%;
      padding: 10px;

      span {
        font-size: 0.8em;
        word-break: break-all;
        color: white;
      }
    }
    input {
      background: #f5f5f5;
      border: none;
      border-top: 1px solid #d3d3d3;
      height: initial;
      font-size: 15px;
      padding: 4px;
    }
    i {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      color: #721c24;
    }
  }
  @media only screen and (max-width: 769px) {
    &--Box {
      display: flex;
      flex-direction: column;
      padding: 15px;
    }
    &--Sidebar {
      order: 2;
    }
    &--Content {
      order: 1;
    }
  }
}
.btn{
    background: var(--accent-color)!important;
}
</style>
